import { Menu } from "antd";
import React from "react";
import { cn } from "../../lib/utils/index";
import { useNavigate } from "react-router-dom";

const NavMenu = ({ selectedTab, setSelectedTab, userType = "", mode }) => {
  const navigate = useNavigate();
  return (
    <Menu
      defaultSelectedKeys={selectedTab}
      selectedKeys={selectedTab}
      mode={mode}
      className={cn(
        "py-4 text-md font-bold  text-lg",
        mode === "horizontal" && "bg-gray-200"
      )}
      items={[
        {
          key: "1",
          label: "Problems",
          onClick: () => {
            navigate(`/home`);
            setSelectedTab(["1"]);
          },
        },
        {
          key: "2",
          label: "Threads",
          onClick: () => {
            navigate(`/threads`);
            setSelectedTab(["2"]);
          },
        },

        {
          key: "3",
          label: "Promptlets",
          onClick: () => {
            navigate(`/promptlets`);
            setSelectedTab(["3"]);
          },
        },
        ...(userType === "admin"
          ? [
              {
                key: "4",
                label: "Admin Only Threads",
                onClick: () => {
                  navigate(`/admin-only-threads`);
                  setSelectedTab(["4"]);
                },
              },
            ]
          : []),
        ...(userType === "admin"
          ? [
              {
                key: "5",
                label: "Users",
                onClick: () => {
                  navigate(`/users`);
                  setSelectedTab(["5"]);
                },
              },
            ]
          : []),

        {
          key: "6",
          label: "About",
          onClick: () => {
            navigate("/about");
            setSelectedTab(["6"]);
          },
        },
        {
          key: "7",
          label: "Help",
          onClick: () => {
            navigate("/help");
            setSelectedTab(["7"]);
          },
        },
      ]}
    />
  );
};

export default NavMenu;
