import { formatDateToStandard, getCookie } from "../../utils/helper";
import { Button } from "antd";
import handleDeletePromptlet from "../../utils/promptlet/handleDeletePromplet";
import { useDispatch, useSelector } from "react-redux";

const DataSourceComponent = (
  promptlets,
  setPromptletToEditModal,
  setViewPrompletModal
) => {
  const dispatch = useDispatch();
  const authUser = JSON.parse(getCookie("authUser") || "{}");
  const { isLoading } = useSelector((state) => state.deleteUser);
  return (
    promptlets.length > 0 &&
    promptlets?.map((item, i) => {
      const isOwner = item?.user?._id === authUser._id;
      const isAdmin = authUser.userType === "admin";
      const isPromptletSharedUnlocked = item?.visibility === "sharedUnlocked";
      const isPromptletPrivate = item?.visibility === "private";
      const isDeleteButtonDisabled =
        (!(isOwner && isPromptletPrivate) &&
          !(isAdmin && isPromptletPrivate)) ||
        isLoading;
      const isEditButtonDisabled = !isOwner && !isAdmin;
      return {
        key: i,
        date: (
          <p className="">
            {formatDateToStandard(item?.created_at)}
          </p>
        ),
        title: item?.name,
        name: item?.user?.name,
        visibility: item?.visibility,
        visibilityElement: item?.visibility,
        action: (
          <div className="flex gap-2">
            <Button
              className={`text-md text-red-500 font-bold ${
                !isDeleteButtonDisabled
                  ? " hover:!bg-red-500 hover:!text-white"
                  : ""
              }`}
              disabled={isDeleteButtonDisabled}
              loading={isLoading}
              onClick={() => handleDeletePromptlet(item?._id, dispatch)}
            >
              Delete
            </Button>
            <Button
              className="text-md font-bold text-green-500 hover:!bg-green-500 hover:!text-white"
              disabled={!(isOwner || isPromptletSharedUnlocked)}
              onClick={() => {
                setPromptletToEditModal({
                  open: true,
                  copy: true,
                  id: item?._id,
                  title: "COPY of " + item?.name,
                  steps: item?.steps,
                  visibility: item?.visibility,
                  variables: item?.variables,
                });
              }}
            >
              Copy
            </Button>
            <Button
              className={`!bg-gradient-to-r ${
                !isEditButtonDisabled
                  ? " !from-blue-500 !to-blue-600 hover:!from-blue-600 hover:!to-blue-700 "
                  : ""
              } !text-white text-md font-bold`}
              disabled={isEditButtonDisabled}
              onClick={() => {
                setPromptletToEditModal({
                  open: true,
                  copy: false,
                  id: item?._id,
                  title: item?.name,
                  steps: item?.steps,
                  visibility: item?.visibility,
                  variables: item?.variables,
                  styleInformation: item?.styleInformation,
                  demoProblem: item?.demoProblem,
                });
              }}
            >
              Edit
            </Button>
            <Button
              className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
              disabled={
                item?.visibility === "sharedLocked" && !isAdmin && !isOwner
              }
              onClick={() => {
                setViewPrompletModal({
                  open: true,
                  id: item?._id,
                  title: item?.name,
                  steps: item?.steps,
                  visibility: item?.visibility,
                  variables: item?.variables,
                });
              }}
            >
              View
            </Button>
          </div>
        ),
      };
    })
  );
};

export default DataSourceComponent;
