import { Label } from "../../components/ui/label";
import AnimatedTitle from "../../components/Common/AnimatedTitle";
import React from "react";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const navigate = useNavigate();
  const handleSubmit = ()=>{
    
  }
  return (
    <div className="bg-black h-screen flex justify-center">
      <div className="flex flex-col gap-10 items-center bg-white border-2 md:w-1/2 w-full md:h-[550px] h-full rounded-md">
        <div className="flex flex-col gap-2 px-2">
          <AnimatedTitle title="Welcome to AutoBA" classContainer="py-4" />
          <div>
            <p className="text-lg font-extralight text-black">
              Make Your{" "}
              <span className="capitalize underline text-blue-600 font-bold">
                bussiness
              </span>{" "}
              Grow.
            </p>
          </div>
        </div>

        <div className="flex w-full flex-col gap-4 px-4 md:px-20">
          <div className="w-full flex flex-col gap-2 ">
            <Label htmlFor="email">Full Name</Label>
            <Input placeholder="John doe"  />
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="email">Email</Label>
            <Input
              className="w-full"
              type="email"
              placeholder="enter@gmail.com"
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="email">password</Label>
            <Input type="password" />
          </div>
          <Button onClick={handleSubmit}>Sign Up</Button>
        </div>
        <p>
          Do you have account already?{" "}
          <span
            className="text-blue-600 cursor-pointer"
            onClick={() => navigate("/")}
          >
            Sign In
          </span>
        </p>
      </div>
    </div>
  );
};

export default Signup;
