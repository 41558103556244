import React, { Fragment } from "react";
import { Typography, Button } from "antd";
import ActionButtons from "./actionButtons";
import RenderContent from "./renderContent";
import handleDebug from "../utils/problemConversation/Actions/handleDebug";
import { getCookie } from "../utils/helper";
import { useDispatch } from "react-redux";
import { getPromptletStep } from "../Redux/Features/Promptlet/getPromptletStepSlice";
import { cn } from "../lib/utils";

const ChatItem = ({
  item,
  i,
  chats,
  isMdOrAbove,
  isSMOrBellow,
  isWritting,
  setIsWritting,
  isResume,
  setChats,
  setOpenDebugModal,
  setStepToEditModal,
  editedResponse,
  setEditedResponse,
  setIsEditorModalVisible,
  setIsChat,
  setIsTyping,
  setMessage,
  problemData,
  problemId,
  message,
}) => {
  const dispatch = useDispatch();
  const user = JSON.parse(getCookie("authUser") || "{}");
  const stepKey = item?.data?.step?.key;
  const isUser = item?.role?.toUpperCase() === "USER";
  const aiChecker = item?.data?.step?.aiChecker;
  const isChecking = item?.isAIChecking;
  const renderActionButtons = () => {
    if (isWritting) return null;
    return (
      <ActionButtons
        item={item}
        isMdOrAbove={isMdOrAbove}
        setOpenDebugModal={setOpenDebugModal}
        setStepToEditModal={setStepToEditModal}
        editedResponse={editedResponse}
        setEditedResponse={setEditedResponse}
        setIsEditorModalVisible={setIsEditorModalVisible}
        chats={chats}
        setIsChat={setIsChat}
        setIsTyping={setIsTyping}
        setMessage={setMessage}
        problemData={problemData}
        problemId={problemId}
        message={message}
        setChats={setChats}
      />
    );
  };

  const renderContent = () => {
    return (
      <RenderContent
        problemId={problemId}
        isResume={isResume}
        componentKey={stepKey}
        content={item?.data[stepKey]?.content}
        title={item?.data[stepKey]?.title}
        chats={chats}
        language={item?.data?.language}
        isWritting={isWritting}
        setIsWritting={setIsWritting}
        setChats={setChats}
      />
    );
  };
  const handleModifyPrompt = (promptletId, stepId) => {
    dispatch(
      getPromptletStep({
        promptletId,
        stepId,
        onSuccess: (step) => {
          setStepToEditModal((prev) => ({
            ...prev,
            promptletId: promptletId,
            open: true,
            step: step,
          }));
        },
      })
    );
  };
  const renderDebugButton = () => {
    if (isWritting) return null;

    return (
      <div className="flex items-center justify-end gap-2 ml-auto">
        <Button
          type="dashed"
          className={`!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 
            ml-auto block ${isSMOrBellow ? "hidden" : ""} text-md font-bold`}
          onClick={() =>
            handleModifyPrompt(
              item?.data?.library?._id,
              item?.data?.library?.stepId
            )
          }
        >
          Modify Prompt
        </Button>
        <Button
          type="dashed"
          className={`ml-auto block text-red-500 ${
            isSMOrBellow ? "hidden" : ""
          } text-md text-center debug-hidden font-bold hover:!bg-red-500 hover:!text-white`}
          onClick={() => {
            handleDebug(item?.data?.library?.stepId, dispatch);
            setOpenDebugModal(true);
          }}
        >
          Debug
        </Button>
      </div>
    );
  };

  const renderContentAndActions = () => (
    <>
      {renderContent()}
      <div className="flex items-center gap-1 m-1" key={i}>
        {renderActionButtons()}
        {(user?.debugLevel !== "none" || user?.userType === "admin") &&
          renderDebugButton()}
      </div>
    </>
  );

  return (
    <div
      className={cn(
        "bg-[#f5f5f5] p-1 rounded-xl",
        isUser && "bg-[#d3d3ff]"
      )}
      key={i}
    >
      {item?.content ? (
        <Typography.Paragraph
          className={cn("text-[14px] mb-2", isUser && "text-blue-700")}
        >
          {isUser ? item?.content : null}
        </Typography.Paragraph>
      ) : (
        <Fragment>
          {item?.data && (
            <>
              {!item?.isResume && aiChecker ? (
                isChecking ? (
                  <div className="" key={stepKey}>
                    <Typography.Paragraph>AI checking...</Typography.Paragraph>
                  </div>
                ) : (
                  renderContentAndActions()
                )
              ) : (
                renderContentAndActions()
              )}
            </>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default ChatItem;
