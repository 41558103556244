import React from "react";
import { userLogout } from "../../Redux/Features/User/userLogoutSlice";
import { toastSuccess } from "../../utils/toast";
import { removeCookie } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ProfileAvator from "./ProfileAvator";
import { FaUserCircle } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";

const Profile = ({ picture, setshowProfile }) => {
  const navigate = useNavigate();
  const dispatch= useDispatch();
  const handleLogout = () => {
    dispatch(
      userLogout({
        onSuccess: () => {
          toastSuccess("User Logged out successfuly");
          sessionStorage.removeItem("tokenExpiration");
          removeCookie("token");
          navigate(`/`);
        },
        onError: () => {},
      })
    );
  };
  return (
    <div className="flex gap-5 me-4  items-center justify-center ">
      {picture ? (
        <ProfileAvator url={picture} setshowProfile={setshowProfile} />
      ) : (
        <FaUserCircle
          fontSize={36}
          className="cursor-pointer text-blue-600"
          onClick={() => setshowProfile(true)}
        />
      )}
      <AiOutlineLogout
        fontSize={21}
        className="text-red-500 cursor-pointer"
        onClick={handleLogout}
        mode="vertical"
      />
    </div>
  );
};

export default Profile;
