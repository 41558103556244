import React from "react";
import { Col, Row, Button } from "antd";
import handleDebug from "../utils/problemConversation/Actions/handleDebug";
import handleAcceptResponse from "../utils/problemConversation/Actions/handleAcceptResponse";
import handleEditResponse from "../utils/problemConversation/Actions/handleEdit";
import handleRejectResponse from "../utils/problemConversation/Actions/handleRejectResponse";
import handleRequeryResponse from "../utils/problemConversation/Actions/handleRequeryResponse";
import { getCookie } from "../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPromptletStep } from "../Redux/Features/Promptlet/getPromptletStepSlice";

const ActionButtons = ({
  item,
  isMdOrAbove,
  setOpenDebugModal,
  setStepToEditModal,
  editedResponse,
  setEditedResponse,
  setIsEditorModalVisible,
  chats,
  setIsChat,
  setChats,
  setIsTyping,
  setMessage,
  problemData,
  problemId,
  message,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading: acceptResponseLoading } = useSelector(
    (state) => state?.acceptResponse
  );
  const user = JSON.parse(getCookie("authUser") || "{}");
  const handleModifyPrompletStep = (promptletId, stepId) => {
    dispatch(
      getPromptletStep({
        promptletId,
        stepId,
        onSuccess: (step) => {
          setStepToEditModal((prev) => ({
            ...prev,
            promptletId: promptletId,
            open: true,
            step: step,
          }));
        },
      })
    );
  };
  const handleCopyResponse = (step)=>{
    const stepKey = step?.key || ""
    const content = item?.data?.[stepKey]?.content;
    navigator.clipboard.writeText(content);

  }
  return (
    <Row justify="space-between" align="middle" gutter={[8, 8]}>
      {!item?.isResume && item?.isMoreButtons && (
        <div className=" grid md:grid-cols-5 grid-rows-2 grid-cols-2 gap-2">
          <Button
            type="dashed"
            onClick={() =>
              handleAcceptResponse(
                {
                  ...item?.data,
                },
                chats,
                dispatch,
                setChats,
                setIsChat,
                setMessage,
                setIsTyping,
                navigate,
                problemData,
                problemId,
                message
              )
            }
            disabled={acceptResponseLoading}
            loading={acceptResponseLoading}
            className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
          >
            Accept
          </Button>

          <Button
            type="dashed"
            disabled={!item?.humanChecker}
            onClick={() => handleRejectResponse(setIsChat, setChats)}
            className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
          >
            Reject
          </Button>

          {!item?.data?.reject && (
            <Button
              type="dashed"
              onClick={() =>
                handleRequeryResponse(
                  chats,
                  setChats,
                  setIsTyping,
                  dispatch,
                  navigate,
                  problemId,
                  problemData
                )
              }
              className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
            >
              Requery
            </Button>
          )}

          <Button
            type="dashed"
            onClick={() =>
              handleEditResponse(
                { ...item?.data },
                editedResponse,
                setEditedResponse,
                setIsEditorModalVisible,
                chats,
                dispatch
              )
            }
            className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
          >
            Edit
          </Button>
          <Button
            type="dashed"
            onClick={() => handleCopyResponse(item?.data?.step)}
            className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
          >
            Copy 
          </Button>
        </div>
      )}
      {(user?.debugLevel !== "none" || user?.userType === "admin") && (
        <div className="flex gap-2">
          <Button
            type="dashed"
            className={`!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 
                     ml-auto block ${isMdOrAbove ? "hidden" : ""} text-md font-bold`}
            onClick={() =>
              handleModifyPrompletStep(
                item?.data?.library?._id,
                item?.data?.library?.stepId
              )
            }
          >
            Modify Prompt
          </Button>

          <Button
            type="dashed"
            className={`ml-auto block text-red-500 ${
              isMdOrAbove ? "hidden" : ""
            } text-md text-center debug-hidden font-bold hover:!bg-red-500 hover:!text-white`}
            onClick={() => {
              handleDebug(item?.data?.library?.stepId, dispatch);
              setOpenDebugModal(true);
            }}
          >
            Debug
          </Button>
        </div>
      )}
    </Row>
  );
};

export default ActionButtons;
