import BasicLayout from "../../Layout/BasicLayout";
import React from "react";

const Help = () => {
  return (
    <BasicLayout>
      <div className="flex justify-center px-4">
        <div className="flex flex-col items-start gap-10 my-10">
          <div className="flex flex-col gap-2">
            <h1 className="font-extralight text-blue-600 special-font uppercase">
              How To Create Problems
            </h1>
            <div className="px-2 py-2 rounded-xl bg-gradient-to-r from-gray-200 to-white flex flex-col gap-2">
              <p>1. Write a meaningfull title about the problem to solve.</p>
              <p>
                2. Write A description about the problem using the language of
                your choice.
              </p>
              <p>3.Choice a library you think it can solve the problem</p>
              <p>4. Select the language the problem to be solved</p>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h1 className="font-extralight text-blue-600 special-font uppercase">
              How To Create ProMptlet
            </h1>
            <div className="px-2 py-2 rounded-xl  bg-gradient-to-r from-gray-200 to-white  flex flex-col gap-2">
              <p>1. Write a meaningfull title about the promptlet to use.</p>
              <p>2. Select the visibility type you want for the promptlet</p>
              <p>3. Add as many steps as you want.</p>
              <p>4.Add variables</p>
              <p>5. Add the default writting style for the LLM </p>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h1 className="font-extralight text-blue-600 special-font uppercase">
              How To create document template{" "}
            </h1>
            <div className="px-2 py-2 rounded-xl bg-gradient-to-r from-gray-200 to-white flex flex-col gap-2">
              <p className="">1. Start solving the problem of your choice</p>
              <p className="">
                2. You will get the name the step your currently at in the chat
                interface. <br />
                And use that name in your decoment .i.e {"{STEP_INIT}"}.
                <br />
                if it is an image, it must be {"{%STEP}"}
              </p>
              <p>4. Add all the steps you want in your document template </p>
              <p>
                5. One you are ready, use this document to merge the chats.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default Help;
