import React from "react";

const ProblemTitle = ({title,library,isOwner,handlePromptletNameClick}) => {
  return (
    <div className="flex flex-col md:flex-row gap-2 items-center">
      <p
        className="text-md text-[#1890ff] font-bold truncate overflow-hidden max-w-xs md:max-w-sm"
        title={title}
      >
        <span>
          {title?.length > 10
            ? `${title?.slice(0, 10)}...`
            : title}
        </span>
      </p>
      <span className="text-lg font-bold text-pink-500">::</span>
      <p
        className={`text-[#1890ff] text-md font-bold truncate overflow-hidden max-w-xs md:max-w-sm ${
          isOwner ? "cursor-pointer underline" : ""
        }`}
        title={library?.name}
        onClick={
          isOwner
            ? () => handlePromptletNameClick(library?._id)
            : undefined
        }
      >
        <span>
          {library?.name?.length > 10
            ? `${library?.name?.slice(0, 10)}...`
            : library?.name}
        </span>
      </p>
    </div>
  );
};

export default ProblemTitle;
