import React from "react";

import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../components/ui/sheet";
import { AiOutlineMenu } from "react-icons/ai";
import NavMenu from "./NavMenu";
import { useNavigate } from "react-router-dom";
const MobileNav = ({ userType, selectedTab, setSelectedTab }) => {
  const navigate = useNavigate();
  return (
    <Sheet>
      <SheetTrigger>
        <AiOutlineMenu className="text-blue-600" fontSize={24}/>
      </SheetTrigger>
      <SheetContent className="flex flex-col gap-4 ">
        <SheetHeader className="mt-10">
          <SheetTitle className="flex items-center pl-4 ">
            <p
              className="text-2xl font-bold text-blue-700 uppercase special-font cursor-pointer"
              onClick={() => navigate("/")}
            >
              <b>A</b>U<b>T</b>oB<b>A</b>
            </p>
          </SheetTitle>
        </SheetHeader>
        <div className="flex flex-col gap-4">
          <NavMenu
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            userType={userType}
          />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default MobileNav;
