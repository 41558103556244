import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <div className="flex bg-gray-900 h-40 items-center justify-between px-4 bg-gradient-to-t from-gray-900 to-gray-700">
        <div className="">
          <p
            className="text-4xl font-bold text-blue-50 cursor-pointer uppercase special-font"
            onClick={() => navigate("/")}
          >
            <b>A</b>U<b>T</b>oB<b>A</b>
          </p>
        </div>
        <div>
          <ul className="list-none flex flex-col gap-2">
            <li className="">
              <a
                href="https://platform.openai.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white font-bold no-underline hover:underline hover:text-blue-400"
              >
                OpenAi
              </a>
            </li>
            <li className="">
              <a
                href="https://plantuml.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white font-bold no-underline hover:underline hover:text-blue-400"
              >
                Plant UML
              </a>
            </li>
            <li className="">
              <a
                href="https://bpmn.io/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white font-bold hover:underline hover:text-blue-400 no-underline"
              >
                BPMN.IO
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="mt-auto py-4 bg-gray-100">
        <p className="text-center text-blue-700 font-bold">
          2024 &copy; All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
