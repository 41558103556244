import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPromptlet } from "../Redux/Features/Promptlet/getPromptletSlice";
import EditPromptletModal from "../components/Promptlets/EditPromptletModal";
import NavMenu from "../components/Common/NavMenu";
import ProfileSetting from "../components/Profile/ProfileSetting";
import Footer from "../components/Common/Footer";
import MergeCompileDocument from "../components/Document/MergeCompileDocument";
import Profile from "../components/Profile/Profile";
import ProblemTitle from "../components/Problems/ProblemTitle";
import MobileNav from "../components/Common/MobileNav";
import Header from "../components/Common/Header";

const BasicLayout = ({
  children,
  isPadding = true,
  setIsMergeDocumentModalVisible,
  problemData,
  isMenu = true,
  handleCompileChats,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showProfile, setshowProfile] = useState(false);
  const [selectedTab, setSelectedTab] = useState([]);
  const user = useSelector((state) => state.getUser.user);
  const { isLoading: updatePromptletLoading } = useSelector(
    (state) => state?.updatePromptlet
  );
  const isOwner = problemData?.library?.user === user?._id;
  const [promptletToEditModal, setPromptletToEditModal] = useState({
    open: false,
    id: "",
    title: "",
    steps: [],
    visibility: "",
    variables: "",
    styleInformation: "",
    demoProblem: "",
  });
  let [clientErrors, setClientErrors] = useState({});

  useEffect(() => {
    let pathName = `/${window.location?.pathname?.split("/")[1]}`;
    switch (pathName) {
      case "/home" || "/chat":
        setSelectedTab(["1"]);
        break;
      case "/threads":
        setSelectedTab(["2"]);
        break;
      case "/promptlets":
        setSelectedTab(["3"]);
        break;
      case "/admin-only-threads":
        setSelectedTab(["4"]);
        break;

      case "/users":
        setSelectedTab(["5"]);
        break;
      case "/about":
        setSelectedTab(["6"]);
        break;
      case "/help":
        setSelectedTab(["7"]);
        break;
    }
  }, []);

  const handlePromptletNameClick = (id) => {
    dispatch(
      getPromptlet({
        id,
        onSuccess: (promptlet) => {
          setPromptletToEditModal((prevState) => ({
            ...prevState,
            open: true,
            id: promptlet._id,
            steps: promptlet.steps,
            title: promptlet.name,
            visibility: promptlet.visibility,
            variables: promptlet.variables,
            styleInformation: promptlet?.styleInformation,
            demoProblem: promptlet?.demoProblem,
          }));
        },
      })
    );
  };
  return (
    <div className="flex h-screen flex-col">
      {isMenu && (
        <Header
          handlePromptletNameClick={handlePromptletNameClick}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          user={user}
          problemData={problemData}
          isOwner={isOwner}
          setshowProfile={setshowProfile}
          isPadding={isPadding}
        />
      )}
      <div className="mb-auto">{children}</div>
      {!isPadding && (
        <MergeCompileDocument
          setIsMergeDocumentModalVisible={setIsMergeDocumentModalVisible}
          handleCompileChats={handleCompileChats}
        />
      )}

      {isOwner && (
        <EditPromptletModal
          promptletToEditModal={promptletToEditModal}
          setPromptletToEditModal={setPromptletToEditModal}
          updatePromptletLoading={updatePromptletLoading}
          setClientErrors={setClientErrors}
          clientErrors={clientErrors}
        />
      )}
      {showProfile && (
        <ProfileSetting
          user={user}
          showProfile={showProfile}
          setshowProfile={setshowProfile}
        />
      )}
      <Footer />
    </div>
  );
};

export default BasicLayout;
