import React from "react";
import { useNavigate } from "react-router-dom";
import MobileNav from "./MobileNav";
import NavMenu from "./NavMenu";
import ProblemTitle from "../Problems/ProblemTitle";
import Profile from "../Profile/Profile";

const Header = ({
  selectedTab,
  setSelectedTab,
  user,
  problemData,
  isOwner,
  handlePromptletNameClick,
  setshowProfile,
  isPadding,
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between gap-6 items-center px-4 bg-gray-200 sticky top-0 z-10 py-1 ">
      <p
        className="hidden md:block text-4xl font-extrabold text-blue-600 uppercase cursor-pointer special-font"
        onClick={() => navigate("/")}
      >
        <b>A</b>U<b>T</b>oB<b>A</b>
      </p>
      <div className="md:hidden block">
        <MobileNav
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          userType={user?.userType}
        />
      </div>

      <div className=" md:w-[65%] hidden md:block">
        <NavMenu
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          userType={user?.userType}
          mode="horizontal"
        />
      </div>

      {problemData && !isPadding && (
        <ProblemTitle
          {...problemData}
          isOwner={isOwner}
          handlePromptletNameClick={handlePromptletNameClick}
        />
      )}

      <Profile {...user} setshowProfile={setshowProfile} />
    </div>
  );
};

export default Header;
